import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25SimpleCollapseComponent } from "./s25.simple.collapse.component";
import { S25IconModule } from "../s25-icon/s25.icon.module";

@NgModule({
    declarations: [S25SimpleCollapseComponent],
    imports: [CommonModule, S25IconModule],
    providers: [S25SimpleCollapseComponent],
    exports: [S25SimpleCollapseComponent],
})
export class S25SimpleCollapseModule {
    constructor() {}
}
