import { DataAccess } from "../../dataaccess/data.access";

export class SpringCacheService {
    // https://knowledge25.collegenet.com/display/S25Dev/How+to+Clear+Spring+Cache+for+Instance
    public static clearCache(namespace?: string, instance?: string) {
        let url = "/cache_management.json?action=invalidate_region";
        namespace && (url = url + "&namespace=" + namespace);
        instance && (url = url + "&instance=" + instance);

        return DataAccess.get(DataAccess.injectCaller(url, "SpringCacheService.clearCache"));
    }
}
