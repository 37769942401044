import { NgModule } from "@angular/core";
import { S25ShiftSelectableDirective } from "./s25.shift.selectable.directive";

@NgModule({
    imports: [],
    exports: [S25ShiftSelectableDirective],
    providers: [S25ShiftSelectableDirective],
    declarations: [S25ShiftSelectableDirective],
})
export class S25ShiftSelectableModule {}
