import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { FBInstance, FreshbooksService } from "../../services/freshbooks.service";
import { SettingsService } from "../../services/settings.service";
import { Bind } from "../../decorators/bind.decorator";
import { S25Util } from "../../util/s25-util";
import { jSith } from "../../util/jquery-replacement";

@Component({
    selector: "s25-ng-freshbooks-config",
    template: ` <div *ngIf="freshbooksInstances">
        <div class="ngBold c-margin-bottom--quarter c-margin-top--single">
            Settings for New Freshbooks Api Integration
        </div>
        <label for="newInstanceName" class="c-margin-bottom--quarter"
            >Enter New Freshbooks Instance Name (max 40 chars)</label
        ><br />
        <input
            name="newInstanceName"
            [(ngModel)]="newFBName"
            type="text"
            maxlength="40"
            class="c-input ng-pristine ng-valid ng-scope ng-empty ng-valid-maxlength ng-touched"
        />
        <button
            class="aw-button aw-button--primary c-margin-bottom--quarte c-margin-left--half"
            (click)="createNewFB()"
        >
            Add Freshbooks Instance
        </button>
        <div *ngFor="let inst of freshbooksInstances">
            <s25-ng-checkbox [(modelValue)]="inst.useNewApi" (modelValueChange)="freshbooksSetUseNewApi(inst)"
                >Use New Api for {{ inst.instance_name }}</s25-ng-checkbox
            >
        </div>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25FreshbooksConfigComponent implements OnInit {
    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        await this.getFreshbooksInstances();
        this.cd.detectChanges();
    }
    //Freshbooks
    freshbooksInstances: FBInstance[];
    newFBName: string = "";
    async createNewFB() {
        if (this.newFBName === "") {
            alert("Enter a name for the new instance");
            return;
        }
        await FreshbooksService.addNewInstance("-1", this.newFBName);
        await this.getFreshbooksInstances();
    }

    freshbooksSetUseNewApi(inst: { instance_name: string; useNewApi: boolean }) {
        return SettingsService.setSetting("USE_NEW_API", inst.useNewApi ? 1 : 0);
    }

    @Bind
    async getFreshbooksInstances() {
        let data = await FreshbooksService.getInstances();
        let fbInstances: FBInstance[] = S25Util.array.forceArray(data?.instances?.instance);
        let promises: Promise<void>[] = [];
        jSith.forEach(fbInstances, (_, inst) => {
            promises.push(
                FreshbooksService.useNewApi(inst.instance_id).then((useNewApi) => {
                    inst.useNewApi = useNewApi;
                }),
            );
        });
        await S25Util.all(promises);
        if (fbInstances.length === 0) {
            fbInstances.push({
                instance_name: "Freshbooks",
                instance_id: 999,
                useNewApi: false,
            } as FBInstance);
        }
        this.freshbooksInstances = fbInstances;
        return;
    }
}
