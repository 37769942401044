import { Directive, ElementRef, HostListener, Input, Self } from "@angular/core";
import { ShiftSelectable } from "./s25.shift.selectable.abstract";

@Directive({
    selector: "[s25-ng-shift-selectable]",
})
export class S25ShiftSelectableDirective {
    @Input() shiftSelectIndex: number; // Used to determine whether this item should react to an event
    @Input() shiftSelectGroup: string; // Group multiple item together to use shift select

    lastIndex: number = Number.MIN_SAFE_INTEGER; // The index of the last clicked item

    // "component" grabs the instance of the component the directive is attached to. The component needs to implement
    // the "ShiftSelectable" class and provide itself with the "ShiftSelectable" token.
    constructor(
        @Self() private component: ShiftSelectable,
        private element: ElementRef,
    ) {}

    // When an item is clicked fire a custom "S25ShiftSelect" event which other items can listen to and decide for
    // themselves whether to select or deselect based on group and index
    @HostListener("mousedown", ["$event"])
    @HostListener("keydown.shift.space", ["$event"])
    onClick(event: KeyboardEvent) {
        const detail: ShiftSelectEvent["detail"] = {
            group: this.shiftSelectGroup,
            selected: this.shiftSelectIndex,
            action: event.shiftKey ? (this.component.isSelected() ? "deselect" : "select") : "none",
        };
        if (event.shiftKey) event.preventDefault(); // Prevent selecting text when shift clicking
        this.element.nativeElement.dispatchEvent(new CustomEvent("S25ShiftSelect", { detail, bubbles: true }));
    }

    // A different item has been clicked, decide whether this item should be selected/deselected as well.
    @HostListener("window:S25ShiftSelect", ["$event"])
    onSelectEvent(event: ShiftSelectEvent) {
        const { group, action, selected } = event.detail;
        if (group !== this.shiftSelectGroup) return; // Event is from different set of items
        const prev = this.lastIndex;
        this.lastIndex = selected; // Store last clicked item
        if (this.shiftSelectIndex === selected) return; // This is the item we clicked. Should be handled by default
        const [low, high] = prev < selected ? [prev, selected] : [selected, prev];
        if (this.shiftSelectIndex < low || this.shiftSelectIndex > high) return; // Item is outside of pertinent range

        if (action === "select") this.component.select();
        else if (action === "deselect") this.component.deselect();
    }
}

export interface ShiftSelectEvent extends Event {
    detail: {
        group: string;
        action: "select" | "deselect" | "none";
        selected: number;
    };
}
