import { S25ServicePortalComponent } from "./s25.service.portal.component";
import { S25LynxUsersComponent } from "./s25.lynx.users.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { NgModule } from "@angular/core";
import { S25SpringCacheComponent } from "./s25.spring.cache.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { S25ErrorsComponent } from "../s25-errors/s25.errors.component";
import { S25FreshbooksConfigComponent } from "./s25.freshbooks.config.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25PreferenceToggleModule } from "../s25-preference-toggle/s25.preference.toggle.module";
import { S25ButtonComponent } from "../../standalone/s25.button.component";

@NgModule({
    declarations: [
        S25ServicePortalComponent,
        S25LynxUsersComponent,
        S25SpringCacheComponent,
        S25FreshbooksConfigComponent,
    ],
    imports: [
        S25CheckboxModule,
        S25DropdownPaginatedModule,
        FormsModule,
        CommonModule,
        S25ErrorsComponent,
        S25EditableModule,
        S25PreferenceToggleModule,
        S25ButtonComponent,
    ],
    providers: [S25ServicePortalComponent, S25LynxUsersComponent, S25FreshbooksConfigComponent],
    exports: [S25ServicePortalComponent],
})
export class S25ServicePortalModule {}
