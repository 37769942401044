import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25SliderComponent } from "./s25.slider.component";

@NgModule({
    declarations: [S25SliderComponent],
    imports: [CommonModule],
    exports: [S25SliderComponent],
})
export class S25SliderModule {
    constructor() {}
}
