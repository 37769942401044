//@author: devin

import { NgModule } from "@angular/core";
import { S25SecurityLinkComponent } from "./s25.security.link.component";
import { S25PipesModule } from "../../pipes/s25.pipes.module";

@NgModule({
    declarations: [S25SecurityLinkComponent],
    imports: [S25PipesModule],
    providers: [S25SecurityLinkComponent],
    exports: [S25SecurityLinkComponent],
})
export class S25SecurityLinkModule {}
